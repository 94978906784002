"use client";
import * as m from "@/../paraglide/messages";

import {
  getCookies,
  healthCheck,
  refreshTokensAndSetCookies,
} from "@/lib/actions";
import { usePathname, useRouter } from "@/lib/i18n";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckCircle } from "@mui/icons-material";

import { useLocalStorage } from "usehooks-ts";

export const LoginButton = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);

  const router = useRouter();
  const [loggedIn, setLoggedIn] = useLocalStorage("loggedIn", false);
  const [loggingOut, setLoggingOut] = useLocalStorage("loggingOut", false);

  const onClick = async () => {
    setIsLoading(true);
    const refreshToken = (await getCookies())?.refresh_token;
    if (refreshToken) {
      try {
        const res = await refreshTokensAndSetCookies(refreshToken);
        if (res && res.status === 200) {
          setLoggingOut(false);
          console.log("Refresh token used successfully");
          setDone(true);
          setLoggedIn(true);
          router.push("/dashboard/");
          setIsLoading(false);
        } else {
          console.log("Refresh token Invalid or Missing");
          setLoggedIn(false);
          router.push("/auth/login");
        }
      } catch (error) {
        console.log("Refresh token Invalid or Missing");
        setLoggedIn(false);
        router.push("/auth/login");
      }
    } else {
      router.push("/auth/login");
      setLoggedIn(false);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <span
        onClick={onClick}
        className="button cursor-pointer h-full flex justify-center items-center"
      >
        {!done ? (
          !isLoading ? (
            children
          ) : (
            <CircularProgress sx={{ color: "white" }} />
          )
        ) : (
          <CheckCircle fontSize="large" sx={{ color: "white" }} />
        )}
      </span>
    </div>
  );
};
