"use client";
import * as m from "@/../paraglide/messages";

import { LoginButton } from "@/components/auth/LoginButton";
import { ThemeSwitcher } from "@/components/ui/ThemeSwitcher";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
} from "@mui/material";
import Image from "next/image";
import { useEffect, useState } from "react";
import useCustomLocalStorage from "@/hooks/useCustomLocalStorage";
import Logo from "./../../public/wase-full-transparent-white-typo.png";
import toast from "react-hot-toast";
import { clearCookies, getCookies, getUserInfo } from "@/lib/actions";
import { Link } from "@/hooks/CustomLink";
import { motion } from "framer-motion";
import { LanguageSelector } from "@/components/ui/Nav bar/LanguageSelector";

export default function Home() {
  const [userData, setUserData] = useState(null);
  const [hydrated, setHydrated] = useState(false);
  const [loggingOut, setLoggingOut] = useCustomLocalStorage(
    "loggingOut",
    false
  );

  useEffect(() => {
    const getTokens = async () => {
      console.log("Getting tokens");
      try {
        const refreshTokenResponse = (await getCookies())?.refresh_token;
        if (typeof refreshTokenResponse === "string") {
          console.log("Refresh Token retrieved, getting user info");
          const userDataResponse = await getUserInfo();
          console.log("User info response:", userDataResponse);
          if (userDataResponse.status === 200) {
            console.log("User info retrieved");
            setUserData(userDataResponse.data);
          } else if (userDataResponse.status === 401) {
            console.warn("Token expired or invalid");
            await clearCookies();
          } else {
            console.log("User info not retrieved, refreshing tokens");
            await clearCookies();
          }
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      } finally {
        setHydrated(true);
      }
    };
    getTokens();
  }, []);

  useEffect(() => {
    if (loggingOut) {
      const logoutSanitized = async () => {
        await clearCookies();
        toast.success("Logout realizado com sucesso");
        setLoggingOut(false);
      };
      logoutSanitized();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Variants for animation
  const variants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24 bg-neutral-200 dark:bg-[#15242b]">
      <div className="absolute top-10 left-10 cursor-pointer bg-[#0D1E26] dark:border dark:border-neutral-200 dark:text-[#15242b] rounded-lg">
        <ThemeSwitcher expanded={true} itemVariants={null} />
      </div>
      <div className="absolute top-30 left-10 cursor-pointer bg-[#0D1E26] dark:border dark:border-neutral-200 dark:text-[#15242b] rounded-lg">
        <LanguageSelector
          expanded={true}
          itemVariants={null}
          disabled={false}
        />
      </div>

      <Card
        sx={{ width: 900, height: 500 }}
        className="bg-[#0D1E26] rounded-xl"
      >
        <CardContent className="relative flex justify-evenly items-center h-full w-full">
          <div className="flex flex-col justify-center items-center w-full gap-6">
            <Image
              src={Logo}
              priority
              alt="Wase logo"
              width={208}
              height={177}
            />
            {!hydrated && <CircularProgress color="secondary" size={48} />}
          </div>
          {hydrated && (
            <>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ duration: 0.5 }}
                className="flex justify-evenly items-center h-full w-full"
              >
                <Divider
                  orientation="vertical"
                  variant="middle"
                  className="bg-neutral-200 dark:bg-neutral-200/20"
                  flexItem
                />
                <div className="w-full flex flex-col justify-center items-center gap-12">
                  <h1 className="text-xl font-bold text-neutral-200 ">
                    {userData
                      ? `${m.bem_vindo_de_volta()}, ${userData?.nome_completo}`
                      : m.bem_vindo_ao_wms_wase()}
                  </h1>
                  <LoginButton>
                    <Button
                      variant="contained"
                      className="rounded w-[150px]"
                      sx={{ background: "#1d323c", color: "#F3F3F3" }}
                    >
                      ENTRAR
                    </Button>
                  </LoginButton>
                </div>
              </motion.div>
            </>
          )}
          {hydrated && userData && (
            <Link href="/auth/login">
              <div className="absolute bottom-5 right-5">
                <p className="font-semibold text-white">
                  {m.entrar_com_outra_conta()}
                </p>
              </div>
            </Link>
          )}
        </CardContent>
      </Card>
    </main>
  );
}
